.external-link {
  white-space: nowrap;
  color: var(--primary-color);
  text-decoration: none;
  border: 1px solid transparent;
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 1px 4px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  .prefix {
    margin-right: 4px;
  }

  &:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    border: 1px solid var(--secondary-bg-color);
  }
}
