* {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--primary-color);
  background-color: var(--background-color);
  font-weight: 400;
  font-size: 14px;
}

main {
  max-width: 700px;
  padding: 40px;
  margin: 0 auto;
}
