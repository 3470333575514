.hero {
  display: flex;
  flex-direction: column;
  gap: 42px;
}

h1,
h2,
p {
  margin: 0;
}

h1 {
  font-size: 32px;
  line-height: 1.2;
  text-align: justify;
  font-weight: 800;
}

p {
  line-height: 1.6;
  font-size: 16px;
  padding: 14px 0px;
}

.video-wrapper {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 372px;
  background-color: var(--video-background-color);

  @media (max-width: 768px) {
    min-height: 240px;
  }
}

.section {
  display: flex;
  flex-direction: column;
}

video {
  width: 100%;
}

.main-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
  }
}

.version-label {
  width: 96px;
  height: 36px;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: var(--background-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  span {
    margin-left: 6px;
  }

  &:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    border: 1px solid var(--secondary-bg-color);
  }
}

pre {
  background-color: var(--secondary-bg-color);
  padding: 12px;
  border-radius: 4px;
  overflow-x: auto;
}
